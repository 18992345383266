<script setup>
import HeaderNav from '@/components/HeaderNav.vue'
import {  inject,ref } from "vue"
import axios from "axios"
import { useStore } from 'vuex'
import CommonFooter from '@/components/CommonFooter.vue'
import CommonModal from '@/components/CommonModal.vue'

const modal = ref(false)
const store = useStore()
const dayjs = inject("dayjs")
const today = dayjs()
const currentDay = ref(today.format('YYYY-MM-DD'))
const selectDays = ref([])
const content = ref({})
const categories = store.state.categories
const places = store.state.places
const rows = store.state.rows

const setSelectDays = ()=>{
  let date = dayjs()
  for (let index = 0; index < 6 ; index++) {
  
    selectDays.value.push({val:date.format('YYYY-MM-DD'),label:date.format('YYYY年MM月DD日（ddd）')}) 
    date= date.add(1 ,'d')
  }
}

const todayRaces = ref([]);
const penndingRaces = ref([]);
const allRace = ref([]);


const getRaces =(date)=>{

  const formData = new FormData()
  formData.append("type","race")
  formData.append("date", date)
  const url = store.state.url + "today_race.php"
    axios.post(url, formData)
  .then(response =>{
    todayRaces.value.length = 0;
    penndingRaces.value.length = 0;
    response.data.forEach(race => {
      const content = {
            'id':race.id,
            'date':race.date,
            'place_name':race.place_name,
            'place':race.place,
            'category':race.category,
             'category_name':store.state.categories.find(e=>e.slug == race.category).name,
            'nth':race.nth,
            'row':race.row,
            'status':race.status
          }
        if(race.status){
          todayRaces.value.push(content);
        }else{
          penndingRaces.value.push(content);
        }
        allRace.value.push(content);
    });
  })
  .catch(error=>{
    console.log(error)
  })
}

getRaces(currentDay.value)
const showChange = (day) =>{

  getRaces(day)
}

const changeContent = ()=>{
  const currentCat = categories.find(e=>e.slug === content.value.category)
  const currentPlace = places.find(e=>e.slug === content.value.place)
  content.value.category_name = currentCat.name
  content.value.place_name = currentPlace.name
  const formData = new FormData()
  formData.append("csrf", store.state.token)
  formData.append("type","update")
  formData.append("data",JSON.stringify(content.value))
  const url = store.state.url + "today_race.php"
    axios.post(url, formData)
  .then(response =>{
    if(response){
      content.value = {}
      getRaces(currentDay.value)
    }else{
      console.log(response);
    }

  })
  .catch(error=>{
    console.log(error)
  })
}
const deleteContent = (id)=>{
  if(!confirm('本当に削除してよろしいですか？')){
    return false;
  }
  const formData = new FormData()
  formData.append("csrf", store.state.token)
  formData.append("type","delete")
  formData.append("id",id)
  const url = store.state.url + "today_race.php"
    axios.post(url, formData)
    .then(response =>{
    if(response){
      alert(`${content.value.place_name}を削除しました`);
      content.value = {}
      getRaces(currentDay.value)
    }else{
      console.log(response);
    }

  })
  .catch(error=>{
    console.log(error)
  })
}

const register = ()=>{
  const currentCat = content.value.category ? categories.find(e=>e.slug === content.value.category ): ''
  const currentPlace = content.value.place ? places.find(e=>e.slug === content.value.place ) : ''
  content.value.place_name = currentPlace.name
  content.value.category_name = currentCat.name 
  content.value.date = currentDay.value 
  const formData = new FormData()
  formData.append("csrf", store.state.token)
  formData.append("type","store")
  formData.append("data",JSON.stringify(content.value))
  const url = store.state.url + "today_race.php"
    axios.post(url, formData)
  .then(response =>{
    if(response){
      alert(`${content.value.place_name}を登録しました`);
      content.value = {}
      getRaces(currentDay.value)
      modal.value = false
    }else{
      console.log(response);
    }

  })
  .catch(error=>{
    console.log(error)
  })

}

const setEdit = (id)=>{
  content.value = {}
    const data = allRace.value.find(e=>e.id === id)
    content.value = data
}

const exist = (id)=>{
    if(content.value.id && content.value.id == id){
      return true
    }else{
      return false
    }
}
setSelectDays()

const newRace = ()=>{
  content.value={}
  modal.value=true
}

</script>


<template>
<div class="flex justify-start flex-col h-screen">
<HeaderNav />
<main>
<div class="text-center py-10">
      <h1 class="text-xl ">本日のレース確認用</h1>
    </div>
    <div  class="w-full text-center  mb-4">
        <form action="" class="">
          <select name="days" id="" class="py-2  px-5 rounded-md border border-gray-200 bg-gray-100"
          v-model="currentDay"
          @change="showChange(currentDay)"
          >
          <option v-for="day in selectDays" :key="day.val" :value="day.val">
          {{ day.label }}
          </option>
        </select>
        </form>
      
      </div>

    <div class="w-full mx-auto max-w-[600px] ">
      <ol v-if="todayRaces.length" class="w-full py-5">
        <li v-for="(race,index) in todayRaces" :key="race.id"
        class="border-b border-gray-300 pb-3 mb-3"
        >
          <div class="flex justify-center items-center">
             <p class="w-[1em] text-center">{{ index+1 }}</p>
             <p class="w-[7em] text-center">{{ race.place_name }}</p>
             <p class="w-[7em] text-center">{{ race.category_name }}</p>
             <p class="w-[7em] text-center">{{ race.nth }}</p>
             <p class="w-[1em] text-center">{{ race.row }}</p>
             <p class="w-[4em] text-center pl-2">
              <button 
              class="ml-2 bg-blue-200 px-2 block rounded hover:bg-blue-300"
              type="button" @click="setEdit(race.id)">編集</button></p>
          </div>
          <div v-if="exist(race.id)" class="border-t border-b border-sky-100 mt-5 py-3 bg-sky-50 ">
            <div class="flex justify-center py-5 w-full  items-center">
              <p class="w-[1em] text-center">{{ index+1 }}</p>
              <p class=" text-center mx-1">
                <select class="border border-gray-200 rounded p-1 text-center" name="place" id="" v-model="content.place">
                  <option v-for="place in places" :key="place.slug"
                  :value="place.slug">{{ place.name }}</option>
                </select>
              </p>
              <p class="text-center mx-1">
                <select class="border border-gray-200 rounded p-1 text-center" name="category" id=""  v-model="content.category">
                  <option v-for="category in categories" :key="category.slug"
                  :value="category.slug">{{ category.name }}</option>
                </select>
              </p>
              <p class="text-center mx-1">
                <input class="border border-gray-200 rounded p-1 text-center"  type="text" v-model="content.nth">
            </p>
              <p class="text-center">
                <select  class="border border-gray-200 rounded p-1 text-center"  name="category" id=""  v-model="content.row">
                  <option v-for="row in rows" :key="row.slug"
                  :value="row.slug">{{ row.slug }}</option>
                </select>
              </p>
              <p class="w-[1em] text-center"></p>
          </div>
          <div class="flex justify-center ">
            <p class="text-center mr-2">
              <button type="button" 
              class="inline-block py-1 px-6  rounded bg-blue-500 text-white"
              @click="changeContent()">更新</button></p>
              <p class="text-center ml-2">
              <button type="button" @click="deleteContent(race.id)"
              class="inline-block py-1 px-6  rounded bg-red-500 text-white"
              >削除</button></p>
          </div>

          </div>
        </li>
      </ol>
      <div>
        <p class="w-2/3 min-w-[200px] mx-auto">
          <button 
            class="bg-blue-500 w-full py-3 rounded text-white hover:bg-blue-700"
          type="button" @click="newRace">新規登録</button></p>
      </div>
    </div>

    <CommonModal v-if="modal"  @modal-close="modal=false">
      <h1 class="text-center my-3 text-lg ">新規登録</h1>
      <form action="" @submit.prevent="register">
      <table class="table-auto w-full border-collapse">
        <tr>
          <th class="w-[8em]">場名</th>
          <td class="px-2 py-5 ">

            <select class="block w-full border border-gray-200 rounded p-1 text-center" name="place" id="" v-model="content.place">
                  <option v-for="place in places" :key="place.slug"
                  :value="place.slug">{{ place.name }}</option>
                </select>
          </td>
        </tr>
        <tr>
          <th  class="w-[8em]">種類</th>
          <td class="px-2 py-5 ">
            <select class="block w-full border border-gray-200 rounded p-1 text-center" name="category" id=""  v-model="content.category">
                  <option v-for="category in categories" :key="category.slug"
                  :value="category.slug">{{ category.name }}</option>
                </select>
          </td>
        </tr>
        <tr>
          <th  class="w-[8em]">〇日目</th>
          <td class="px-2 py-5 ">
            <input class="block w-full border border-gray-200 rounded p-1 text-center"  type="text" v-model="content.nth">
          </td>
        </tr>
        <tr>
          <th class="w-[8em]">行列</th>
          <td class="px-2 py-5 ">
            <select  class="block w-full border border-gray-200 rounded p-1 text-center"  name="category" id=""  v-model="content.row">
                  <option v-for="row in rows" :key="row.slug"
                  :value="row.slug">{{ row.slug }}</option>
                </select>

          </td>
        </tr>
         
      </table>
      <p class="mt-10 text-center w-[150px] mx-auto"> <input 
        class="bg-blue-500 w-full py-3 rounded text-white hover:bg-blue-700"
        type="submit" value="登録"></p>
       
     
    </form>

      </CommonModal>

</main>

<CommonFooter/>
  </div>
</template>


<style>

</style>
